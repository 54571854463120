._stats{
    .stats{
        h2 {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 0;
        } 
        p {
            font-size: 13px;
            color: #888;
        } 

        table{
            width: 100%;
            tr{
                background: #f5f5f5;
                &:nth-child(odd){
                    background: none;
                }
                th{
                    font-size: 13px;
                    background: #000;
                    color: #fff; 
                    padding: 5px 5px; 
                }
                td{
                    font-size: 14px;
                    color: #666;
                    padding: 10px 5px; 
                }
            }
        }

        &.stats-reactions{
            .filter-by-date{
                > div{
                    flex-grow: 1;
                    &:first-child{
                        padding-right: 5px;
                    }
                    &:last-child{
                        padding-left: 5px;
                    }
                }
            }
            table{
                width: 100%;
                tr{ 
                    td,th{
                        text-align: center;
                        &:first-child{
                            text-align: left;
                        }
                    }
                }
            }
            .nav-tabs{
                margin-top: 30px; 
            }
            .tab-content{
                padding-top: 20px;
            }
            
        }
    }
}