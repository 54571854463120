
._administration{
    @import "~bootstrap/scss/bootstrap-reboot.scss";
    @import "~bootstrap/scss/_grid.scss";
    @import "~bootstrap/scss/_nav.scss";
    @import "~bootstrap/scss/_forms.scss";
    @import "~bootstrap/scss/_buttons.scss";
    @import "~bootstrap/scss/_dropdown.scss";
    margin-top: 0;
    width: 100%;
    padding: 0 20px; 
    .header-admin{
        position: relative;
        margin-bottom: 10px;
        h3{
            display: flex;
            align-items: center;
            width: 100%;
            height: 35px;
            margin: 0;
            center{
                width: 100%;
                text-transform: uppercase;
                color: #fff;
                a{
                    color: #fff;
                    text-decoration: none;
                    float: left;
                    border: 1px solid #fff;
                    border-radius: 4px;
                    padding: 3px 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    cursor: pointer;
                }
            }
        }
    }
    .box{
        padding:20px;
        background: #fff;
        border-radius: 10px;

        .icons-menu{
            .row{
                > div{
                    margin-bottom: 20px;
                }
            }
            .menu-item{
                text-decoration: none;
                color: #000;
                box-shadow: 0 0 10px #0000002e;
                display: block;
                padding: 20px;
                border-radius: 30px;
                height: 100%;
                min-height: 163px; 
                h3{
                    font-size: 18px;
                }
                p{
                    font-size: 13px;
                    color: #888;
                }
            }
        }
        
        h2 {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 0;
        } 
        p {
            font-size: 13px;
            color: #888;
        } 
        form{
            input[type=text],input[type=number], textarea{
                border: 1px solid #e3e3e3;
            }
            input[type=file]{
                border: 1px solid #e3e3e3;
                padding: 10px;
            }
            .dropdown {
                &-toggle{
                    display: block;
                    width: 100%;
                    max-width: 196px;
                    text-align: left;
                    position: relative;
                    &:after{
                        position: absolute;
                        right: 10px;
                        top: 16px;
                    }
                } 
            }
            .form-group{
                label{
                    position: static;
                    padding-left: 0;
                }
                .input-color {
                    width: 100%;
                    height: 40px;
                    input[type="text"] {
                        padding-left: 0;
                        font-size: 13px;
                    }
                }
            }
            .nav-tabs{
                margin-top: 20px;
                .nav-link{
                    color: #495057;
                    &.active{
                        color: var(--secondary-color);
                    }
                }
            }
            .web-colors {
                padding: 0 20px 5px;
                border: 1px solid #dedede;
                border-radius: 10px;
                margin: 10px 0 0 0;
            }
            .link{
                color: #787878;
                text-decoration: underline;
            }

            button{
                
                background-color: var(--secondary-color);
                border: 0;
            }
            .submit-container{
                margin-top: 15px;
                padding-top: 15px; 
                width: 100%;
                display: block;
                text-align: right;
                span{
                    margin-left: 5px; 
                    position: relative;
                    top: 1px;
                }
            }
        }

        .administration-banners, .administration-headband{
            .listing{
                .row{
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #cacaca;
                    align-items: center;
                    .item-image {
                        width: 100px;
                        height: 100px;
                        background-size: cover;
                        background-position: center;
                    }
                    .link{
                        a{
                            word-break: break-all;
                            color: #404347;
                            text-decoration: underline;
                        }
                    }
                    .edit-item{
                        background: rgb(255, 166, 0);
                        border: 0;
                        color: #fff;
                        font-size: 16px;
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        border-radius: 50px;
                        box-shadow: 0 0 9px #aaa;
                        margin-right: 5px;
                    }
                    .delete-item{
                        background: red;
                        border: 0;
                        color: #fff;
                        font-size: 16px;
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        border-radius: 50px;
                        box-shadow: 0 0 9px #aaa;
                    }
                }
            }
            form{
                padding-top: 15px;
                margin-top: 15px; 
            }
            &.administration-headband{
                .activate-option{
                    border-top: 1px solid #eee;
                    border-bottom: 1px solid #eee;
                    margin-bottom: 30px;
                    label{
                        margin-bottom: 0;
                    }
                    .radio-button{
                        margin-top: 8px;
                    }
                }
                .listing{
                    .row{ 
                        .item-image {
                            width: 30px;
                            height: 30px;
                            background-size: cover;
                            background-position: center;
                        } 
                    }
                }

                form{
                    .react-editor{
                        border: 1px solid #f1f1f1;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}