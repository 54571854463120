.breaking-news {
    background: rgb(190, 0, 0); 
    border-top: 1px solid #5a0d0d;
    border-bottom: 1px solid #5a0d0d;
    color: #fff;
    bottom: 0;
    padding: 7px 0;
    width: 100%;
    margin-top: 15px;
    // max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}